<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf()">问卷列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">问卷详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="searchbox changetreestyle">
            <div style="margin-left: -30px" class="flexcb searchbox">
              <h3>问卷基本信息</h3>
            </div>
            <div class="flexdc" style="align-items: flex-start; width: 100%">
              <el-form
                :rules="rules"
                :model="ruleForm"
                ref="basicInfoForm"
                style="width: 100%"
              >
                <el-form-item
                  label="问卷名称："
                  label-width="100px"
                  style="margin-right: 20px"
                  prop="paperName"
                >
                  <p v-if="editBtn == true">{{ ruleForm.paperName }}</p>
                  <el-input
                    v-model="ruleForm.paperName"
                    maxlength="50"
                    show-word-limit
                    v-else
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="问卷描述："
                  label-width="100px"
                  style="margin-right: 20px"
                  prop="paperDescription"
                >
                  <p v-if="editBtn == true">{{ ruleForm.paperDescription }}</p>
                  <el-input
                    type="textarea"
                    v-model="ruleForm.paperDescription"
                    size="small"
                    maxlength="1200"
                    show-word-limit
                    v-else
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-form v-if="source == '平台'">
                <el-form-item v-if="editBtn">
                  <el-button
                    style="padding: 8px 20px"
                    class="bgc-bv"
                    @click="doEdit"
                    >编辑</el-button
                  >
                </el-form-item>
                <el-form-item v-else>
                  <el-button
                    style="padding: 8px 20px"
                    class="bgc-bv"
                    @click="doSave"
                    >保存</el-button
                  >
                  <el-button
                    style="padding: 8px 20px"
                    class="bgc-bv"
                    @click="doGive"
                    >取消</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="framePage-scroll flexdc">
            <div
              class="bottom searchbox"
              style="padding-top: 0; padding-right: 1rem"
            >
              <h3>问卷内容信息</h3>
              <div class="flexcb" style="align-items: flex-start">
                <el-form :inline="true">
                  <el-form-item label="题目内容:" style="margin-right: 20px">
                    <el-input v-model="questionName" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="题目类型:" style="margin-right: 20px">
                    <el-select
                      v-model="questionType"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in questionTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      style="padding: 8px 20px"
                      class="bgc-bv"
                      @click="chaxun"
                      >查询</el-button
                    >
                    <el-button
                      v-if="source == '平台'"
                      style="padding: 8px 20px; aling-self: flex-start"
                      class="bgc-bv"
                      @click="exportquestion(paperId)"
                      >导入题目</el-button
                    >
                    <el-button
                      v-if="source == '平台'"
                      style="padding: 8px 20px; aling-self: flex-start"
                      class="bgc-bv"
                      @click="addquestion(paperId)"
                      >增加</el-button
                    >
                  </el-form-item>
                </el-form>
                <div></div>
              </div>
            </div>
            <div class="ovy-a flex1" id="list-box">
              <div class="list" v-for="(item, index) in tableData" :key="index">
                <div class="list-item df">
                  <!-- <span
                  :class="tixing {item.questionType == 0? 'tixing1':item.questionType == 1 ? 'tixing2':item.questionType == 2 ? 'tixing3':item.questionType == 3 ? 'tixing4':''}"
                  >{{ $setDictionary("QUESTIONTYPE", item.questionType) }}</span
                  >-->
                  <span
                    class="tixing"
                    :class="{
                      tixing1: item.questionType == 10,
                      tixing2: item.questionType == 20,
                      tixing3: item.questionType == 30,
                    }"
                    >{{
                      $setDictionary("SR_QUESTION_TYPE", item.questionType)
                    }}</span
                  >
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span
                        >{{ indexMethod(index) }}、{{ item.questionName }}</span
                      >
                    </div>
                    <div class="option" v-if="item.questionType != 30">
                      <span
                        v-for="(item1, index) in item.optionBOList"
                        :key="index"
                        style="display: flex; flex-direction: column"
                      >
                        {{ optionFM(index) }}、{{ item1.optionName }}
                        <!-- <el-image
                            style="width:50px"
                            v-if="item1.optionImgUrl"
                            class="qbimg"
                            :src="
                              item1.optionImgUrl ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image> -->
                      </span>
                    </div>
                    <div
                      v-if="source == '平台'"
                      class="df"
                      style="
                        flex-wrap: nowrap;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex: 1;
                      "
                      v-show="stu != 'platform'"
                    >
                      <div style="white-space: nowrap">
                        <el-button
                          style="padding: 8px 20px"
                          v-show="
                            index !== 0 &&
                            questionName == '' &&
                            questionType == ''
                          "
                          @click="onSort(item.questionId, true)"
                          >上移</el-button
                        >
                        <el-button
                          style="padding: 8px 20px"
                          v-show="
                            index !== tableData.length - 1 &&
                            questionName == '' &&
                            questionType == ''
                          "
                          @click="onSort(item.questionId, false)"
                          >下移</el-button
                        >
                        <el-button
                          style="padding: 8px 20px"
                          @click="onEdit(item.questionId)"
                          >编辑</el-button
                        >
                        <el-button
                          style="padding: 8px 20px"
                          @click="onDel(item.questionId, item.paperId)"
                          >删除</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <qusetionnairepaper
      ref="qusetionnairepaper"
      @getnewdata="getQueryQuestionData"
      @subShows="subShows"
    />
    <qusetionnairepaper2
      ref="qusetionnairepaper2"
      @getnewdata="getQueryQuestionData"
      @subShows="subShows"
    />
    <questionaireexport
      ref="questionaireexport"
      @getnewdata="getQueryQuestionData"
      @subShows="subShows"
    />
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import qusetionnairepaper from "@/views/operate/questionnaireList/popUp/qusetionnairepaper";
import qusetionnairepaper2 from "@/views/operate/questionnaireList/popUp/qusetionnairepaper2";
import questionaireexport from "@/views/operate/questionnaireList/popUp/questionaireexport";
import List from "@/mixins/List";
export default {
  name: "testPaperEdit",
  components: {
    // Empty,
    qusetionnairepaper,
    qusetionnairepaper2,
    questionaireexport,
  },
  mixins: [List],
  data() {
    return {
      source: "", // 来源状态
      activeName: "first",
      stu: "add",
      editBtn: true, //
      form: {
        type: "1",
        radio: null,
        checkList: [],
        testnum: null,
        selectState: true,
      },
      paperId: "",
      ruleForm: {
        paperName: "",
        paperDescription: "",
      },
      questionTypeList: [],
      tableData: [],
      questionName: "",
      questionType: "",
      rules: {
        paperName: [
          { required: true, message: "请输入问卷名称", trigger: "blur" },
        ],
        paperDescription: [
          { required: true, message: "请输入问卷描述", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {
    // const height = document.getElementById('list-box').offsetHeight;
  },
  computed: {},
  methods: {
    getQuestionTypeList() {
      const questionTypeList = this.$setDictionary("SR_QUESTION_TYPE", "list");
      const list = [{ value: "", label: "全部" }];
      for (const key in questionTypeList) {
        list.push({
          value: key,
          label: questionTypeList[key],
        });
      }
      this.questionTypeList = list;
    },
    subShows(params) {
      this.subShow = params;
    },
    init() {
      const query = this.$route.query;
      this.paperId = query.paperId;
      this.source = query.source;

      if (this.paperId) {
        this.getEdit(this.paperId);
        this.getQueryQuestionData();
      }
      this.getQuestionTypeList();
    },

    doSave() {
      const that = this;
      //   const basicInfoForm = this.$refs.basicInfoForm;
      let params = {
        paperName: that.ruleForm.paperName,
        paperDescription: that.ruleForm.paperDescription,
        paperId: that.paperId,
      };
      that.$refs["basicInfoForm"].validate((valid) => {
        if (valid) {
          that.$post("/survey/sr-paper/modifyPaperInfo", params).then((ret) => {
            that.$message({
              message: ret.message,
              type: "success",
            });
            that.editBtn = true;
            that.getEdit(that.paperId);
          });
        }
      });
    },
    doGive() {
      this.editBtn = true;
      this.$refs["basicInfoForm"].resetFields();
      this.getEdit(this.paperId);
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    getQueryQuestionData() {
      const params = {
        paperId: this.paperId,
      };
      if (this.questionName) {
        params.questionName = this.questionName;
      }
      if (this.questionType) {
        params.questionType = this.questionType;
      }
      this.$post("/survey/sr-paper/queryQuestionList", params)
        .then((ret) => {
          this.tableData = ret.data;
        })
        .catch((err) => {
          return;
        });
      //   this.doFetch({
      //     url: "/survey/sr-paper/queryQuestionList",
      //     params,
      //     pageNum
      //   });
      //   this.getEdit(this.parperId);
    },
    chaxun() {
      this.getQueryQuestionData();
    },
    //问卷基本信息回显
    getEdit(paperId) {
      this.$post("/survey/sr-paper/getPaperInfo", {
        paperId,
      }).then((ret) => {
        this.ruleForm = { ...this.ruleForm, ...ret.data };
      });
    },
    //
    doEdit() {
      this.editBtn = false;
    },
    // 上下移
    onSort(questionId, stu) {
      this.$post("/survey/sr-paper/questionUpDown", { questionId, isUp: stu })
        .then((ret) => {
          if (ret.status == 0) {
            this.getQueryQuestionData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 试题删除
    onDel(questionId, paperId) {
      const that = this;
      that
        .$confirm("你确定要从问卷中删除该问题吗", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that
            .$post("/survey/sr-paper/deleteQuestion", { questionId })
            .then((ret) => {
              if (ret.status == 0) {
                that.getQueryQuestionData();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          return;
        });
    },
    onEdit(questionId) {
      this.$refs.qusetionnairepaper2.showPopUp(questionId);
    },
    addquestion(paperId) {
      console.log(paperId);
      this.$refs.qusetionnairepaper.showPopUp(paperId);
    },
    //导入题目
    exportquestion(paperId) {
      console.log(paperId);
      this.$refs.questionaireexport.showPopUp(paperId);
    },
    goHerf() {
      this.$router.push({
        path: "/web/operate/questionnaireList",
        query: {
          refresh: true,
        },
      });
    },
  },
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
        }
      }
    }
  }
}
.searchbox {
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.searchbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.changetreestyle {
  .treeinput {
    .el-input {
      flex: 0;
    }
  }
}
.treeinput {
  .el-form-item__content {
    width: 36rem;
  }
}
</style>
