<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="50%"
    top="0"
    center
    @close="closeDialog"
    title="导入问卷题目"
    :close-on-click-modal="false"
  >
    <el-form ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules">
     <el-form-item label="下载模板" class="form-item">
                <el-button @click="downloadExcel">下载</el-button>
              </el-form-item>
              <el-form-item label="上传试题" prop="fileName">
                <el-upload
                  v-model="ruleForm.fileName"
                  v-if="!ruleForm.fileName"
                  class="upload-demo upload-btn"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button size="small" class="bgc-bv">选取问卷题目</el-button>
                </el-upload>
                <span style="color:#f46173" v-else>
                  {{ ruleForm.fileName }}
                  <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                </span>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>只能上传.XLS 和 .XLSX格式的单个文件，且文件大小不超过1MB！</p>
              </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
      <el-button class="bgc-bv" @click="addOptions">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";

export default {
  name: "ChestLotteryList",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      centerDialogVisible: false,
      parperId: "",
      ruleForm:{
          fileName:'',
      },
      rules: {
        fileName: [{ required: true, message: "请上传试卷", trigger: "blur" }]
      }
    };
  },
  created() {
    
  },
  mounted() {
    
  },
 
  computed: {},
  methods: {
    init() {
    },
    showPopUp(paperId) {
      const that = this;
      that.paperId = paperId;
      that.centerDialogVisible = true;
    },
    addOptions() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const that = this;
          let params = {
          paperId:this.paperId,
        excelUrl: this.ruleForm.excelUrl,
      };
      if (that.ruleForm.excelUrl == "") {
        that.$message.warning("请先上传问卷题目");
        return false;
      }
      that.$post("/survey/sr-paper/readSrPaperExcel", params)
        .then(() => {
          that.centerDialogVisible = false;
                that.$emit("getnewdata");
                that.$emit("subShows", true);
        })
        .catch(err => {
          return;
        });
        }
      });
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/问卷内容模板.xls";
      link.setAttribute("download", "问卷内容模板.xls");
      document.body.appendChild(link);
      link.click();
    },
   uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.excelUrl = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    docancle() {
      this.centerDialogVisible = false;
      this.empty();
    },
    empty() {
      this.$refs['ruleForm'].resetFields();
    },
    closeDialog() {
      this.centerDialogVisible = false;
      this.empty();
    },
  }
};
</script>
<style lang="less">
.upload-btn {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
<style lang="less" scoped>

.el-form-item__content {
  display: flex;
  align-items: center;
  
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}
</style>
