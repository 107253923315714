<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="50%"
    top="0"
    center
    @close="closeDialog"
    title="新增问卷题目"
    :close-on-click-modal="false"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="添加试题" name="first"></el-tab-pane>
      <!-- <el-tab-pane label="从题库添加试题" name="second"></el-tab-pane> -->
    </el-tabs>
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="题目类型" required>
        <el-radio-group
          v-model="form.questionType"
          @change="formTypeChange"
        >
          <el-radio :label="10">单选题</el-radio>
          <el-radio :label="20">多选题</el-radio>
          <el-radio :label="30">填空题</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="题目内容" prop="questionName"  class="areaBox">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入题目内容"
          v-model="form.questionName"
        ></el-input>
      </el-form-item>
      <el-form-item label  v-if="
          (form.questionType == 10 || form.questionType == 20)
        ">
        <div>
            <div
              v-for="(item1, index) in form.optionBOList"
              :key="index"
              style="display: flex;align-items: center;margin-bottom:18px"
            >
              <el-form-item :prop="'optionBOList[' + index + '].optionName'" class="optionName">
               {{ optionFM(index) }}
                <el-input
                  v-model="item1.optionName"
                  style="width:220px"
                ></el-input>
                 <!-- <el-upload
                  :on-change="
                    (res, file) => {
                      return handleAvatarSuccess2(res, file, index);
                    }
                  "
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                     item1.optionImgUrl ||
                        require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload> -->
                <el-button
                  v-if="index > 1"
                  class="de"
                  type="text"
                  @click="delOpenList(index)"
                  >删除选项</el-button
                >
              </el-form-item>
            </div>
        </div>
      </el-form-item>
      <el-form-item
        label
        v-if="
          (form.questionType == 10 || form.questionType == 20) && form.optionBOList.length < 6
        "
      >
        <el-button @click="addFromOpenList">添加选项</el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
      <el-button class="bgc-bv" @click="addOptions">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";

export default {
  name: "ChestLotteryList",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      editBtn: true, //
      centerDialogVisible: false,
      paperList: [],
      form: {
        questionType: 10,
        questionName: '',
        optionBOList: [{optionName:'',optionImgUrl:''},{optionName:'',optionImgUrl:''}],
       
      },
      parperId: "",
      rules: {
        questionName: [
          { required: true, message: "请输入题目内容", trigger: "blur" }
        ],
      
        "optionBOList[0].optionName": [
          { required: true, message: "请输入选项内容", trigger: "blur" }
        ],
        "optionBOList[1].optionName": [
          { required: true, message: "请输入选项内容", trigger: "blur" }
        ],
        "optionBOList[2].optionName": [
          { required: true, message: "请输入选项内容", trigger: "blur" }
        ],
        "optionBOList[3].optionName": [
          { required: true, message: "请输入选项内容", trigger: "blur" }
        ],
        "optionBOList[4].optionName": [
          { required: true, message: "请输入选项内容", trigger: "blur" }
        ],
        "optionBOList[5].optionName": [
          { required: true, message: "请输入选项内容", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    
  },
  mounted() {
    
  },
 
  computed: {},
  methods: {
    init() {
    },
    showPopUp(paperId) {
      const that = this;
      that.paperId = paperId;
      that.centerDialogVisible = true;
    },

    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    addOptions() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const that = this;
          let parmar = {
            questionOrder: that.form.questionOrder,
            paperId: that.paperId,
            questionName: that.form.questionName,
            questionType: that.form.questionType,
            optionDTOList:that.form.optionBOList
          };
          that
            .$post("/survey/sr-paper/saveOrUpdateQuestion", parmar)
            .then(ret => {
              if (ret.status == 0) {
                that.centerDialogVisible = false;
                that.$emit("getnewdata");
                that.$emit("subShows", true);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    },
    delOpenList(index) {
      this.form.optionBOList.splice(index, 1);

   
    },
    addFromOpenList() {
      let data = {
        optionName: "",
        optionImgUrl:'',
      };
      this.form.optionBOList.push(data);
      
     
      this.$forceUpdate();
    },
      //单选题图片上传
    handleAvatarSuccess2(res, fiie, index) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.form.optionBOList[index].optionImg =
            result.data.fileKey || "";
          that.form.optionBOList[index].optionImgUrl =
            result.data.fileURL || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    docancle() {
      this.centerDialogVisible = false;
      this.empty();
    },
    empty() {
      this.$refs['form'].resetFields();
    },
    closeDialog() {
      this.centerDialogVisible = false;
      this.empty();
    },
    formTypeChange(value) {
      this.$refs['form'].resetFields();
     
    }
  }
};
</script>
<style lang="less">
.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}

.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: 9.375rem;
        }
      }
    }
  }
}
.searchbox {
  padding: 0.9rem 0 0 1.775rem;
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.showtitle {
      color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
}
.areaBox  {
  .el-textarea {
    .el-textarea__inner {
      min-height:150px !important;
       resize: none;
    }
  }
}
</style>
<style lang="less" scoped>
.el-form-item__content {
  display: flex;
  align-items: center;
  
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}
</style>
